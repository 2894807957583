body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.makeStyles-root-1 {
  -webkit-animation: fadeInDown  0.7s linear;
  -moz-animation: fadeInDown  0.7s linear;
  animation: fadeInDown  0.7s linear;
}    
@-moz-keyframes fadeInDown  {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
@-webkit-keyframes fadeInDown  {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes fadeInDown  {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.article {
  -webkit-animation: fadeInUp  0.5s forwards;
  -moz-animation: fadeInUp  0.5s forwards;
  animation: fadeInUp  0.5s forwards;
}

@keyframes fadeInUp  {
  0% {
    transform: translateY(150px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}


#dot:hover {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@keyframes rotate {
  to {
    stroke-dashoffset: 227;
  }
}
